import LinkPromotionsRequest from '@/@api/linkPromotions'
import { ref, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { debouncedWatch } from '@vueuse/core'
import constants from '@/@core/constants'

const links = ref([])

export default function useLinkPromotions() {
  // Use toast
  const toast = useToast()

  const refLinkListTable = ref(null)

  // const links = ref([])

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { label: 'Nombre', key: 'name', sortable: true },
    {
      label: 'Link',
      key: 'uri',
      sortable: true,
      thStyle: { width: '100%' },
    },
    { label: 'Idiomas', key: 'langs', sortable: true },
    { label: 'Países', key: 'countries', sortable: true },
    { label: 'Activo', key: 'status', sortable: true },
    { label: 'Acciones', key: 'actions' },
  ]
  const perPage = ref(10)
  const totalLinks = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLinkListTable.value ? refLinkListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLinks.value,
    }
  })

  const refetchData = () => {
    refLinkListTable.value.refresh()
  }

  debouncedWatch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
    refetchData()
  }, { debounce: 400 })

  const fetchLinks = (ctx, callback) => {
    LinkPromotionsRequest.list({
      params: {
        q: searchQuery.value,
        paginate: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        status: statusFilter.value,
      },
    })
      .then(response => {
        const { data, total } = response.data.data

        callback(data.map(link => ({ ...link, loading: false })))
        totalLinks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching links list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // async function fetchLinksSelector(isMemo = false) {
  //   if (isMemo && links.value.length) {
  //     return Promise.resolve(links.value)
  //   }

  //   try {
  //     const { data } = await LinkPromotionsRequest.select()
  //     console.log(data)

  //     const list = data.data.map(link => ({
  //       id: link.id,
  //       value: link.iso?.toUpperCase() || link.id,
  //       label: link.name,
  //     }))

  //     links.value = list

  //     return list
  //   } catch (error) {
  //     throw new Error(error)
  //   }
  // }

  async function linkStore(body) {
    try {
      const form = {
        ...body,
        css_selector_id: constants.LINK_PROMOTION_SELECTOR_ID,
      }

      const { data } = await LinkPromotionsRequest.store(form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkUpdate(id, body) {
    try {
      const form = {
        ...body,
        css_selector_id: constants.LINK_PROMOTION_SELECTOR_ID,
      }

      const { data } = await LinkPromotionsRequest.update(id, form)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  async function linkDelete(id) {
    try {
      const { data } = await LinkPromotionsRequest.destroy(id)

      toast({
        component: ToastificationContent,
        props: {
          title: data.message,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    } catch (error) {
      throw new Error(error)
    }
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // fetchLinksSelector(true).then(list => {
  //   links.value = list
  // })

  // const resolveIsoLink = iso => {
  //   if (!iso) return ''

  //   const dic = links.value.reduce((acc, link) => {
  //     acc[link.value] = link.label

  //     return acc
  //   }, {})

  //   return dic[iso] ?? iso
  // }

  return {
    links,
    fetchLinks,
    // fetchLinksSelector,
    tableColumns,
    perPage,
    currentPage,
    totalLinks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLinkListTable,

    // resolveIsoLink,

    refetchData,
    linkStore,
    linkUpdate,
    linkDelete,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
