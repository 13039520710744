import http from './http'
import Resource from './resource'

const path = 'admin/css-selectors'

class CssSelector extends Resource {
  constructor(_http) {
    super(path, _http)
  }
}

const CssSelectorRequest = new CssSelector(http)

export default CssSelectorRequest
