<template>
  <b-sidebar
    id="add-new-selector-sidebar"
    :visible="isAddNewSelectorSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-selector-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Selector css
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nombre identificador -->
          <validation-provider
            #default="validationContext"
            name="Nombre identificador"
            rules="required"
          >
            <b-form-group
              label="Nombre identificador"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="selectorData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Selector -->
          <validation-provider
            #default="validationContext"
            name="Selector"
            rules="required"
          >
            <b-form-group
              label="Selector"
              label-for="selector"
            >
              <b-form-textarea
                id="selector"
                v-model="selectorData.selector"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                rows="3"
                max-rows="6"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <template v-if="loading">
                <b-spinner
                  variant="light"
                  class="mr-75"
                  small
                />
                <span>{{ selectorData.id ? 'Actualizando' : 'Creando' }}</span>
              </template>
              <span v-else>{{ selectorData.id ? 'Actualizar' : 'Crear' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormInvalidFeedback,
  BButton,
  // BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
// import vSelect from 'vue-select'
import countriesList from '@/@fake-db/data/other/countries'
// import store from '@/store'
import useCssSelector from './useCssSelector'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    // BFormCheckbox,
    BSpinner,
    // vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSelectorSidebarActive',
    event: 'update:is-add-new-selector-sidebar-active',
  },
  props: {
    isAddNewSelectorSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countriesList,
    }
  },
  watch: {
    isAddNewSelectorSidebarActive(val) {
      if (val && this.item) {
        this.selectorData = {
          id: this.item.id,
          name: this.item.name,
          selector: this.item.selector,
        }
      // } else {
      //   this.selectorData = {
      //     id: null,
      //     name: '',
      //     selector: '',
      //   }
      //   this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      name: '',
      selector: '',
    }

    const selectorData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      selectorData.value = JSON.parse(JSON.stringify(blankData))
    }

    const loading = ref(false)
    const { selectorStore, selectorUpdate } = useCssSelector()

    // onMounted(async () => {
    //   const countryList = await fetchCountriesSelector()
    //   const langList = await fetchLangsSelector()

    //   countries.value = countryList
    //   langs.value = langList
    // })

    const onSubmit = async () => {
      loading.value = true

      const data = { ...selectorData.value }
      // data.countries = Array.isArray(data.countries) ? data.countries.map(i => i.id) : data.countries.id
      // data.langs = Array.isArray(data.langs) ? data.langs.map(i => i.id) : data.langs.id

      try {
        if (data.id) {
          await selectorUpdate(data.id, data)
        } else {
          await selectorStore(data)
        }

        emit('refetchData')
        emit('update:is-add-new-selector-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      selectorData,
      loading,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
