<template>
  <div>
    <link-promotions-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :item.sync="linkEditable"
      @refetchData="refetchData"
    />
    <css-selector-add-new
      :is-add-new-selector-sidebar-active.sync="isAddNewSelectorSidebarActive"
      :item.sync="selectorEditable"
      @refetchData="loadSelector"
    />

    <b-card
      no-body
    >
      <div class="card-header">
        <b-card-title>Links de Promociones</b-card-title>

        <!-- <b-form-group
          label="Selector css"
          label-for="name"
          style="width: 420px"
        >
          <b-form-input
            id="name"
            v-model="selectorCss"
            placeholder="Selector css"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            autofocus
            trim
          />
        </b-form-group> -->
        <b-button
          variant="primary"
          @click="isAddNewSelectorSidebarActive = true"
        >
          Selector css
        </b-button>
      </div>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Selector css</label>
            <b-form-input
              v-model="selectorCss"
              class="d-inline-block mx-50"
              placeholder="Selector css"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
            /> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                :disabled="!selectorEditable"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Nuevo link</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refLinkListTable"
        :items="fetchLinks"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        responsive="sm"
        primary-key="id"
        empty-text="No se encontraron links"
        show-empty
        striped
      >
        <!-- Column: langs -->
        <template #cell(langs)="data">
          <div class="d-flex flex-column align-items-start">
            <div
              v-for="lang in data.item.langs"
              :key="lang.id"
              class="px-1"
              style="margin-bottom: 4px"
            >
              {{ lang.name ? lang.name : lang.label }}
            </div>
          </div>
        </template>

        <!-- Column: countries -->
        <template #cell(countries)="data">
          <div class="d-flex flex-column align-items-start">
            <div
              v-for="country in data.item.countries"
              :key="country.id"
              class="px-1"
              style="margin-bottom: 4px"
            >
              {{ country.name ? country.name : country.label }}
            </div>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          {{ data.item.status ? 'SI' : 'NO' }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`link-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="onEdit(data.item)"
            />
            <b-tooltip
              title="Editar idioma"
              :target="`link-row-${data.item.id}-edit-icon`"
            />

            <div class="d-inline">
              <feather-icon
                v-if="!data.item.loading"
                :id="`link-row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                size="16"
                class="cursor-pointer"
                @click="onDelete(data.item)"
              />
              <b-spinner
                v-if="data.item.loading"
                :id="`link-row-${data.item.id}-delete-icon`"
                small
              />
              <b-tooltip
                :title="data.item.loading ? 'Eliminando...' : 'Eliminar link'"
                :target="`link-row-${data.item.id}-delete-icon`"
              />
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalLinks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  // BFormGroup,
  BButton,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { onMounted, ref } from '@vue/composition-api'
import CssSelectorAddNew from '@core/components/css-selector/CssSelectorAddNew.vue'
import useCssSelector from '@/@core/components/css-selector/useCssSelector'
import constants from '@/@core/constants'
import LinkPromotionsAddNew from './LinkPromotionsAddNew.vue'
import useLinkPromotions from './useLinkPromotions'

export default {
  components: {
    LinkPromotionsAddNew,
    CssSelectorAddNew,

    BCard,
    BTable,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    // BFormGroup,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
  },
  setup() {
    const {
      fetchLinks,
      linkDelete,
      tableColumns,
      perPage,
      currentPage,
      totalLinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refLinkListTable,
      refetchData,

      // UI
      // resolveUserRoleVariant,
      // resolveUserRoleIcon,
      // resolveUserStatusVariant,

      // Extra Filters
      // roleFilter,
      // planFilter,
      // statusFilter,
    } = useLinkPromotions()
    const {
      selectorShow,
    } = useCssSelector()

    const isAddNewUserSidebarActive = ref(false)
    const isAddNewSelectorSidebarActive = ref(false)
    const linkEditable = ref(null)
    const selectorEditable = ref(null)

    async function loadSelector() {
      try {
        const data = await selectorShow(constants.LINK_PROMOTION_SELECTOR_ID)
        console.log(data)
        selectorEditable.value = data
      } catch (error) {
        console.log(error)
      }
    }

    onMounted(async () => {
      await loadSelector()
    })

    function onEdit(country) {
      linkEditable.value = { ...country }

      isAddNewUserSidebarActive.value = true
    }

    async function onDelete(link) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          // eslint-disable-next-line no-param-reassign
          link.loading = true
          await linkDelete(link.id)
          // eslint-disable-next-line no-param-reassign
          link.loading = false
          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El link ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    return {
      isAddNewUserSidebarActive,
      isAddNewSelectorSidebarActive,
      linkEditable,
      selectorEditable,
      refLinkListTable,

      loadSelector,
      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      totalLinks,
      dataMeta,
      perPageOptions,
      searchQuery,
      fetchLinks,
      refetchData,
      onEdit,
      onDelete,
    }
  },
}
</script>
