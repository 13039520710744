<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ linkData.id ? 'Editar' : 'Nuevo' }} Idioma
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Nombre -->
          <validation-provider
            #default="validationContext"
            name="Nombre"
            rules="required"
          >
            <b-form-group
              label="Nombre"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="linkData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Link -->
          <validation-provider
            #default="validationContext"
            name="Link"
            rules="required"
          >
            <b-form-group
              label="Link"
              label-for="uri"
            >
              <b-form-input
                id="uri"
                v-model="linkData.uri"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Idiomas -->
          <validation-provider
            #default="validationContext"
            name="Idiomas"
            rules="required"
          >
            <b-form-group
              label="Idiomas"
              label-for="langs"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="linkData.langs"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="langs"
                :clearable="false"
                :close-on-select="false"
                input-id="langs"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Países -->
          <validation-provider
            #default="validationContext"
            name="Países"
            rules="required"
          >
            <b-form-group
              label="Países"
              label-for="countries"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="linkData.countries"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countries"
                :clearable="false"
                :close-on-select="false"
                input-id="countries"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Status -->
          <validation-provider
            name="Activo"
          >
            <b-form-group
              label="Activo"
              label-for="statusCheck"
            >
              <b-form-checkbox
                id="statusCheck"
                v-model="linkData.status"
                name="statusCheck"
              >
                {{ linkData.status ? 'Si' : 'No' }}
              </b-form-checkbox>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              :disabled="loading"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <template v-if="loading">
                <b-spinner
                  variant="light"
                  class="mr-75"
                  small
                />
                <span>{{ linkData.id ? 'Actualizando' : 'Creando' }}</span>
              </template>
              <span v-else>{{ linkData.id ? 'Actualizar' : 'Crear' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              :disabled="loading"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { onMounted, ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countriesList from '@/@fake-db/data/other/countries'
// import store from '@/store'
import useCountries from '../countries/useCountries'
import useLangs from '../langs/useLangs'
import useLinkPromotions from './useLinkPromotions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormCheckbox,
    BSpinner,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countriesList,
    }
  },
  watch: {
    isAddNewUserSidebarActive(val) {
      if (val && this.item) {
        this.linkData = {
          id: this.item.id,
          name: this.item.name,
          uri: this.item.uri,
          langs: this.item.langs?.map(lang => ({
            id: lang.id,
            label: lang.name,
            value: lang.iso,
          })),
          countries: this.item.countries?.map(country => ({
            id: country.id,
            label: country.name,
            value: country.iso,
          })),
          status: this.item.status,
        }
      } else {
        this.linkData = {
          id: null,
          name: '',
          uri: '',
          langs: [],
          countries: [],
          status: true,
        }
        this.$emit('update:item', null)
      }
    },
  },
  setup(_, { emit }) {
    const blankData = {
      id: null,
      name: '',
      uri: '',
      langs: [],
      countries: [],
      status: true,
    }

    const linkData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      linkData.value = JSON.parse(JSON.stringify(blankData))
    }

    const loading = ref(false)
    const { fetchCountriesSelector } = useCountries()
    const { fetchLangsSelector } = useLangs()
    const { linkStore, linkUpdate } = useLinkPromotions()
    const countries = ref([])
    const langs = ref([])

    onMounted(async () => {
      const countryList = await fetchCountriesSelector()
      const langList = await fetchLangsSelector()

      countries.value = countryList.map(c => ({ ...c, value: c.id }))
      langs.value = langList.map(l => ({ ...l, value: l.id }))
    })

    const onSubmit = async () => {
      loading.value = true

      const data = { ...linkData.value }
      data.countries = Array.isArray(data.countries) ? data.countries.map(i => i.id) : data.countries.id
      data.langs = Array.isArray(data.langs) ? data.langs.map(i => i.id) : data.langs.id

      try {
        if (data.id) {
          await linkUpdate(data.id, data)
        } else {
          await linkStore(data)
        }

        emit('refetchData')
        emit('update:is-add-new-user-sidebar-active', false)
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)

    return {
      linkData,
      loading,
      onSubmit,

      countries,
      langs,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
